import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import BigNumber from 'bignumber.js';
import { DlgContractService } from '../dlg-contract.service';

import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AddressBookEntryDTO,
  AddressBookServiceProxy,
  DealDTO,
  DealRegistrationDTO,
  DealServiceProxy,
  UserDTO,
  UsersServiceProxy
} from '../../service-proxies/service-proxies';
import { NetworkNamePipe } from '../../shared/pipes/networkName.pipe';
import { DlgAddressBookComponent } from '../dlg-address-book';
import { environment } from 'src/environments/environment';
import {DlgDisclaimerComponent} from '../dlg-disclaimer/dlg-disclaimer.component';
import { documentDeals} from './detail-deal.resolver';
import {TranslateService} from "@ngx-translate/core";
import swal from "sweetalert2";

@Component({
  selector: 'deal-detail',
  templateUrl: './deal-detail.component.html',
  styleUrls: ['./deal-detail.component.scss'],
})
export class DealDetailComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private _dialog: MatDialog,
    private _dlgContractSrv: DlgContractService,
    private _alertSrv: AlertService,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private route: ActivatedRoute,
    private router: Router,
    private dealService: DealServiceProxy,
    private usersService: UsersServiceProxy,
    private addressBookServiceProxy: AddressBookServiceProxy,
    public translate: TranslateService
  ) {
    super();

    this.now = Math.floor(Date.now() / 1000);

    this.updateTimeTimerId = setInterval(() => {
      this.now = Math.floor(Date.now() / 1000);

      let diffStart = 0;
      if (this.isUpcoming) {
        diffStart = this.startTimestamp - this.now;
      }
      else if (this.isStarted) {
        diffStart = this.finishTimestamp - this.now;
      }
      if (diffStart > 0) {
        this.timerViewDays = Math.floor(diffStart / (3600 * 24));
        this.timerViewHours = Math.floor(diffStart % (3600 * 24) / 3600);
        this.timerViewMin = Math.floor(diffStart % 3600 / 60);
        this.timerViewSec = Math.floor(diffStart % 60);
      }
      else {
        this.timerViewDays = 0;
        this.timerViewHours = 0;
        this.timerViewMin = 0;
        this.timerViewSec = 0;
      }
    }, 1000);
  }

  public readonly defaultClaimingUrl: string = environment.defaultClaimingUrl;

  userInWhitelist: boolean = false;
  isCollectWalletDeal: boolean = false;
  disclaimerAgreed: boolean = false;
  emissionAddress: string;

  deal: DealDTO;
  userInfo: UserDTO;
  dealRegistration: DealRegistrationDTO;
  addressBookEntry: AddressBookEntryDTO;

  kycConfirmed: boolean = false;
  isAuthorized: boolean = false;

  waiting: boolean = false;
  account: string = '';
  dealAddress: string = '';
  balance: string = '0';

  //#region contract data
  public isApprovedPaymentToken: boolean = false;
  paymentToken: string;

  tokenPrice: string;
  rewardToken: string;
  rewardDecimals: number = 18;
  dealStartTimestamp: number;
  startTimestamp: number;
  finishTimestamp: number;
  startClaimTimestamp: number;
  maxDistributedTokenAmount: string;
  totalRaise: string; // Sum of all payments (in payment token)
  tokensForDistribution: string;
  leftTokensForDistribution: string;
  minimumRaise: string;
  distributedTokens: string = '0';
  allowRefund: boolean;
  feePercent: number = 0;
  oldDeal: boolean = false;

  vestingContractAddress: string;
  vestingPercent: number;
  vestingStart: number;
  vestingInterval: number;
  vestingDuration: number;

  //#end region contract data
  paymentDecimal: number;
  paymentTokenSymbol: string;
  rewardTokenSymbol: string;
  tokensPerETH: number;
  targetNetwork: number;

  userTier: any;
  userTierIndex: number = -1;
  userBonusTierIndex: number = -1;
  bonusInterval: number = 0;
  isBonusActive: boolean = false;
  allTiers: any[] = new Array();
  allowance:any;
  allLockupsTiers: any[] = [];
  userLockupsTierIndex: number = -1;

  updateTimeTimerId: NodeJS.Timeout;
  updateUserTimerId: NodeJS.Timeout;
  updateDealTimerId: NodeJS.Timeout;

  now: number;

  timerViewDays: number;
  timerViewHours: number;
  timerViewMin: number;
  timerViewSec: number;

  //Last participation time
  lastParticipationTime: number = 0;
  lockupDuration: number = 0;

  claimURL: string;
  waitingCheckCustomTier: boolean = true;

  public get amountInputClass(): string {
    return this.userCustomRaise > 0 ? "ticket-increased ticket-increased-input" : "";
  }

  noDocumentsOrAllSignedAndCompleted:boolean = false;
  payButtonDisabled:boolean = false;
  requiredDocument:boolean = false;
  payInputDisabled:boolean = false;
  documentInProgress: string;

  public get needToWaitTime(): number {
    let needTiWait = this.lastParticipationTime + this.lockupDuration - this.now;
    if (needTiWait > 0)
      return needTiWait;
    else
      return 0;
  }

  public usersTicketSize = 0;
  interesAmount = 0;
  userCustomRaise = 0;

  public usersTicketSizeInRewardTokens = 0;

  public get usersTicketSizePattern() {
    return this.deal.dealType === 2 ? /[0-9]/ : /[0-9,.]/;
  }

  public storagePath(key:string): string {
    return `${this.dealAddress}.${key}.${this.userSessionProvider.linkedWallet}`;
  }

  public get interesWasShown(): boolean {
    return !localStorage.getItem(this.storagePath('interes'));
  }

  public get interesWasSet(): boolean {
    return !!localStorage.getItem(this.storagePath('interes'));
  }

  public get interesAccepted(): boolean {
    return !!localStorage.getItem(this.storagePath('interes-accepted'));
  }

  public get interesDeclined(): boolean {
    return !!localStorage.getItem(this.storagePath('interes-declined'));
  }

  public setInteresting(): void {
    localStorage.removeItem(this.storagePath('interes-declined'));
    localStorage.setItem(this.storagePath('interes'), '1');
  }

  public setNotInteresting(): void {
    localStorage.removeItem(this.storagePath('interes'));
    localStorage.setItem(this.storagePath('interes-declined'), '1');

  }

  public async applyInteres(): Promise<void> {
    await this.approve(this.interesAmount).then(async (isApproved)=>{
      if(!isApproved)return;
      await this.dealService.setInteres(this.dealAddress, this.interesAmount)
      .then(() => {
        localStorage.setItem(this.storagePath('interes-accepted'), '1');
      });
    });

  }

  public async resetInteres(): Promise<void>{
    await this.approve(0).then(async (isApproved)=>{
      if(!isApproved)return;
      await this.dealService.resetInteres(this.dealAddress)
      .then(() => {
        localStorage.removeItem(this.storagePath('interes-accepted'));
      });
    });

  }

  public async recalcTicketSizeChange(target: any): Promise<void> {
    if (this.deal.dealType === 3 && this.usersDebtAmount < this.deal.minRewardTokenAmount && target.value < this.deal.minRewardTokenAmount / this.tokensPerETH) {
      this.usersTicketSize = this.deal.minRewardTokenAmount / this.tokensPerETH
    }
    /*if (this.deal.dealType === 3 && target.value > this.maxEquityPaymentAmount) {
      this.usersTicketSize = this.maxEquityPaymentAmount
    }*/
    this.handleTicketSizeChange({value: this.usersTicketSize * this.tokensPerETH, recalc: true});
    await this.updateUserData();
  }

  public async handleTicketSizeChange(target: any): Promise<void> {
    let value = +target.value;
    if ((this.deal.dealType === 2 || this.deal.dealType === 3) && /[\-0-9,.]*/.test(target.value)){
      value = Math.round(value);
    }

    if (value  < 0) {
      value = 0;
    }

    if (this.deal.dealType === 2 && value < 1){
      value = 1;
    }

    if (this.deal.dealType === 3 && value  < 1) {
      value = 1;
    }

    if (this.deal.dealType === 3 && value > this.userTierMaxTokens - this.usersDebtAmount) {
      value = this.userTierMaxTokens - this.usersDebtAmount
    }

    if (this.deal.dealType === 3 && this.usersDebtAmount < this.deal.minRewardTokenAmount && value < this.deal.minRewardTokenAmount) {
      value = this.deal.minRewardTokenAmount
    }

    if (this.deal.dealType === 3) {
      this.usersTicketSize = Number((value / this.tokensPerETH).toFixed(4));
      this.usersTicketSizeInRewardTokens = Number(value.toFixed(4));
    }else {
      const allowedValue = this.decreaseWithFee(this.userTierMaxTokens - this.usersDebtAmount);

      if (value > allowedValue){
        value = allowedValue;
      }

      this.usersTicketSize = Number((value / this.tokensPerETH).toFixed(4));
      this.usersTicketSizeInRewardTokens = Number(value.toFixed(4));

      if (target.recalc) {
        this.usersTicketSizeInRewardTokens = this.decreaseWithFee(this.usersTicketSizeInRewardTokens);
      }
      else {
        this.usersTicketSize = Number((this.increaseWithFee(value) / this.tokensPerETH).toFixed(4));
      }
    }
    await this.updateUserData();
  }
  public handleKeyDown(event: any) {
    return this.deal.dealType !== 2 || event.charCode >= 48 && event.charCode <= 57;
  }

  get userTierMaxTokens() {
    if (this.userTier?.ticketSize) {
      return new BigNumber(this.userTier.ticketSize).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).shiftedBy(-this.paymentDecimal).toNumber();
    }
    return 0;
  }

  get ticketSizeToNumber(): number {
    if (this.userTier?.ticketSize) {
      return new BigNumber(this.userTier.ticketSize).shiftedBy(-this.paymentDecimal).toNumber();
    }
    return 0;
  }

  get purchasedTokensToNumber(): number {
    return new BigNumber(this.totalRaise).shiftedBy(-this.paymentDecimal).toNumber();
  }

  get maxEquityPaymentAmount(): number {
    return this.getTotalRaiseETH(this.deal) - new BigNumber(this.totalRaise).shiftedBy(-this.paymentDecimal).toNumber()
  }

  get calculateTheMaxNumberForEquityDeal(): number {
    if (this.usersTotalPaymentAmount) {
      if (this.usersTotalPaymentAmount >= this.ticketSizeToNumber) {
        return 0
      }else {
        if (this.ticketSizeToNumber - this.usersTotalPaymentAmount >= this.ticketSizeToNumber - new BigNumber(this.userTier.purchasedTokens).shiftedBy(-this.paymentDecimal).multipliedBy(this.deal.tokenPrice).shiftedBy(-this.paymentDecimal).toNumber()) {
          return this.ticketSizeToNumber - new BigNumber(this.userTier.purchasedTokens).shiftedBy(-this.paymentDecimal).multipliedBy(this.deal.tokenPrice).shiftedBy(-this.paymentDecimal).toNumber()
        }else {
          return this.ticketSizeToNumber - this.usersTotalPaymentAmount
        }
      }
    }else {
      if(this.ticketSizeToNumber <= new BigNumber(this.userTier.allocation).shiftedBy(-this.paymentDecimal).multipliedBy(this.deal.tokenPrice).shiftedBy(-this.paymentDecimal).toNumber() - new BigNumber(this.userTier.purchasedTokens).shiftedBy(-this.paymentDecimal).multipliedBy(this.deal.tokenPrice).shiftedBy(-this.paymentDecimal).toNumber()) {
        return this.ticketSizeToNumber
      }else {
        return new BigNumber(this.userTier.allocation).shiftedBy(-this.paymentDecimal).multipliedBy(this.deal.tokenPrice).shiftedBy(-this.paymentDecimal).toNumber() - new BigNumber(this.userTier.purchasedTokens).shiftedBy(-this.paymentDecimal).multipliedBy(this.deal.tokenPrice).shiftedBy(-this.paymentDecimal).toNumber()
      }
    }
  }
  async setMaxUsersTicketSize(num?: number, num2?: number): Promise<void> {
    if(this.payInputDisabled){
      return;
    }
    const max: any = this.userTierMaxTokens;
    this.usersTicketSize = Number((this.ticketSizeToNumber - num).toFixed(4));
    this.usersTicketSizeInRewardTokens = Number((max - num2).toFixed(4));
    this.usersTicketSizeInRewardTokens = this.decreaseWithFee(this.usersTicketSizeInRewardTokens);

    await this.updateUserData();
  }

  increaseWithFee(amount: number): number {
    if (!this.feePercent) return amount;
    else {
      return Number((amount / (1 - (this.feePercent / 100.0))).toFixed(4));
    }
  }

  decreaseWithFee(amount: number): number {
    if (!this.feePercent) return amount;
    else {
      var feePart = amount * this.feePercent / 100.0;
      return Number((amount - feePart).toFixed(4));
    }
  }

  usersDebtAmount: number;
  usersTotalPaymentAmount: number;
  usersClaimedTokensAmount: number;
  usersReleasableAmount: number = 0;
  usersVestedAmount: number = 0;
  usersVestedReleasedAmount: number = 0;

  public get payTooltip(): string {
    if (this.isBonusActive) {
      return this.translate.instant('payDelayedAfterDealStart', { minutes: Math.floor(this.bonusInterval / 60) })
    }
    else {
      return this.translate.instant('theTimeWhenWillBeAbleMakePayment')
    }
  }

  public get allowClaim(): boolean {
    if (!this.startClaimTimestamp || this.startClaimTimestamp == 0 || this.deal?.hideTgeDate) {
      return false;
    }
    return this.now > this.startClaimTimestamp;
  }

  public get isUpcoming(): boolean {
    return this.now < this.startTimestamp;
  }

  public get isStarted(): boolean {
    return this.now > this.startTimestamp && this.now < this.finishTimestamp;
  }

  public get isFinished(): boolean {
    return this.now > this.finishTimestamp;
  }

  public get allowPay(): boolean {
    if (!this.startTimestamp) {
      return false;
    }
    if (!this.finishTimestamp) {
      return false;
    }

    if (this.userInWhitelist) {
      return this.now < this.finishTimestamp;
    }
    else {
      return this.now > this.startTimestamp && this.now < this.finishTimestamp;
    }
  }

  public get canPayMore(): boolean {
    return new BigNumber(this.totalRaise).lt(new BigNumber(this.maxDistributedTokenAmount)) &&
           new BigNumber(this.usersTotalPaymentAmount).toNumber() < new BigNumber(this.userTier?.ticketSize).shiftedBy(-18).toNumber();
  }

  public get getDistributedPercent(): number {
    if (this.maxDistributedTokenAmount && this.tokensForDistribution) {
      return new BigNumber(this.tokensForDistribution).div(this.maxDistributedTokenAmount).multipliedBy(100).toNumber();
    }
    return 0;
  }

  public getDistributedPercentTier(maxDistributedTokenAmountTier: number, tokensForDistributionTier: number): number {
    if (maxDistributedTokenAmountTier && tokensForDistributionTier) {
      return new BigNumber(tokensForDistributionTier).div(maxDistributedTokenAmountTier).multipliedBy(100).toNumber();
    }
    return 0;
  }

  public get getDistributedLeft(): string {
    if (this.maxDistributedTokenAmount && this.tokensForDistribution) {
      if(this.deal.customPrice){
        return new BigNumber(this.maxDistributedTokenAmount).minus(this.tokensForDistribution).multipliedBy(this.deal.tokenPrice).dividedBy(this.deal.customPrice).toString();
      }
      return new BigNumber(this.maxDistributedTokenAmount).minus(this.tokensForDistribution).toString();
    } else {
      return '0';
    }
  }

  public getTotalRaiseETH(deal: DealDTO): number {
    let result = 0;
    result = new BigNumber(deal.maxDistributedTokenAmount).shiftedBy(-deal.rewardDecimal).multipliedBy(deal.tokenPrice).shiftedBy(-deal.paymentDecimal).toNumber();
    // if(this.deal.customPrice){
    //   result =  new BigNumber(deal.maxDistributedTokenAmount).shiftedBy(-deal.rewardDecimal).multipliedBy(deal.customPrice.toString()).shiftedBy(-deal.paymentDecimal).toNumber();
    // }
    // else{
    //   result = new BigNumber(deal.maxDistributedTokenAmount).shiftedBy(-deal.rewardDecimal).multipliedBy(deal.tokenPrice).shiftedBy(-deal.paymentDecimal).toNumber();
    // }
    return result;
  }

  async ngOnInit() {
    console.log('currentLang', this.translate.currentLang);
    this.isAuthorized = this.userSessionProvider.isAuthorized;
    await this.updateUserTier();

    this.route
      .queryParams
      .subscribe(params => {
        this.dealAddress = params['address'];
        console.log(`deal address: ${this.dealAddress}`);
        this.dealService.getByAddress(this.dealAddress).subscribe(result => {
          this.deal = result;
          this.rewardTokenSymbol = this.deal.rewardTokenSymbol;
          this.isCollectWalletDeal = this.deal.isDealWithoutDistribution;
          this.paymentTokenSymbol = this.deal.paymentTokenSymbol;
          this.targetNetwork = this.deal.targetNetwork;
          this.claimURL = this.deal.claimURL;

          if (!this.isAuthorized && this.deal.dealType == 3) {
            this.router.navigate(["/login"]);
          }

          /*this.web3Service.checkCustomTiers(this.dealAddress, this.userSessionProvider.linkedWallet).then((res) => {
            if (res <= 0 && this.deal.dealType == 3) {
              this.router.navigate(['/']);
            }
          }).finally(() =>{
            this.waitingCheckCustomTier = false
          });*/

          console.log('account=' + this.account);
          console.log('targetNetwork=' + this.targetNetwork);
          this.addressBookServiceProxy.getByUserWalletAndChainId(this.userSessionProvider.linkedWallet, this.targetNetwork)
            .subscribe(result => {
              this.addressBookEntry = result;
              this.emissionAddress = this.addressBookEntry?.emissionAddress;
            },
              error => {
                console.error(error);
              });
          console.log(this.deal);
          this.checkWhiteList();
          if (this.web3Service.chainIdNumber != this.deal.chainId) {
            const chainName = new NetworkNamePipe().transform(this.deal.chainId);
            this.showErrorModal(this.translate.instant('selectNetworkInMetamask', { chainName }));
            this.router.navigate(['/deals']);
          }
        },
          error => {
            console.error(error);
          });
      });

    if (this.userSessionProvider.isAuthorized) {
      this.usersService.getMe()
        .subscribe(
          result => {
            this.userInfo = result;
            this.kycConfirmed = result.kycConfirmed;
          },
          error => {
            this.processServiceError(error);
          }
        );

      this.dealService.getRegistration(this.dealAddress)
        .subscribe(
          result => {
            this.dealRegistration = result;
          },
          error => {
            this.processServiceError(error);
          }
        );

        if(documentDeals.includes(this.dealAddress.toLowerCase())){
          await this.checkDocumentsAndStatuses();
        }
    }

    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);
    this.paymentDecimal = 18;
    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

    await this.web3Service.initWeb3();
    if (this.web3Service.web3) {
      await this.updateDealContractData();

      await this.updateDealDataInterval();
      this.updateDealTimerId = setInterval(() => {
        this.updateDealDataInterval();
      }, this.expectedBlockTime);
    }

    if (this.userSessionProvider.linkedWallet) {
      await this.eventLogin(this.userSessionProvider.linkedWallet);
      if (this.deal.paymentToken != '0x0000000000000000000000000000000000000000') {
        this.web3Service.GetTokenBalance(this.account, this.deal.paymentToken).then((value) => {
          this.balance = this.toNumberFromWeiFixed(value, 18).toString();
        })
      }else {
        this.web3Service.getEthBalance(this.account).then((value) => {
          this.balance = this.toNumberFromWeiFixed(value, 18).toString();
        });
      }
    }

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });
  }

  private async checkDocumentsAndStatuses():Promise<any>{
    const userDocuments = await this.dealService.getDocuments(this.dealAddress, this.userSessionProvider.linkedWallet).toPromise();
    const userDoucmentsHashes = userDocuments.map((i:any)=>i.hash);
    const documentsStatuses = await this.usersService.getUserDocumentsStatuses(this.userSessionProvider.linkedWallet).toPromise();
    const documentsStatusesFilterdByDeal = documentsStatuses.filter((i:any)=> userDoucmentsHashes.includes(i.documentHash));
    const anyDocRequiredSignment = documentsStatusesFilterdByDeal.filter((i:any)=> i.status == "sent");
    const notCompletedDocuments = userDocuments.filter((i:any)=> i.isCompleted == false);

    console.log('userDocuments', userDocuments);
    console.log('userDoucmentsHashes', userDoucmentsHashes)
    console.log('documentsStatuses', documentsStatuses);
    console.log('documentsStatusesFilterdByDeal', documentsStatusesFilterdByDeal);
    console.log('anyDocRequiredSignment', anyDocRequiredSignment);
    console.log('notCompletedDocuments', notCompletedDocuments);

    if(userDocuments.length == 0 || !anyDocRequiredSignment.length && !notCompletedDocuments.length){
      // no documents
      this.noDocumentsOrAllSignedAndCompleted = true;
      return true;
    }
    else {
      if(anyDocRequiredSignment.length){
        this.requiredDocument = true;
        this.payInputDisabled = true;
        this.usersTicketSize = userDocuments.filter((i:any)=> i.hash == notCompletedDocuments[0].hash)[0].amount;
        this.showWarningModal(this.translate.instant('youCantParticipateInDealYouNotSignDocuments'));
        return false;
      }

      if(notCompletedDocuments.length > 0){
        this.payInputDisabled = true;
        this.usersTicketSize = userDocuments.filter((i:any)=> i.hash == notCompletedDocuments[0].hash)[0].amount;
        this.documentInProgress = notCompletedDocuments[0].hash;
        return true;
      }
    }
  }

  checkWhiteList(): void {
    if (this.deal.enabledWhitelisting && this.userSessionProvider.isAuthorized) {
      //Check if user in white list
      this.dealService.checkWhiteList(this.dealAddress).subscribe(result => {
        this.userInWhitelist = result.value;
      },
        error => {
          console.error(error);
        });
    }
  }

  async eventLogin(username: string) {
    console.log('eventLogin');
    console.log(username);
    if (this.account != username) {
      this.account = username;

      //TODO:HACK call early than updateDealContractData
      await this.setRewardDecimals();

      this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
      if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
        this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
      }
      else {
        this.paymentDecimal = 18;
        this.isApprovedPaymentToken = true;
      }

      await this.updateUserData();

      this.updateUserTimerId = setInterval(() => {
        this.updateUserData();
      }, this.expectedBlockTime);

      //this.getTokensDebts(username);
    }
  }

  eventLogout(): void {
    console.log('signOut')
    this.account = "";

    //this.tokenDebt = 0;
    //this.totalInvestedETH = 0;
    //this.ethBalance = 0;
    //this.totalStakeETH = null;
    //this.totalBuyToken = null;
    //this.myClaimedTokensAmount = null;
    //this.tokensDebt = new Array();

    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
  }

  async payClick() {
    if (!this.dealRegistration) {
      this.showWarningModal(this.translate.instant('clickJoinDeal'));
      return;
    }
    if (this.needToWaitTime > 0) {
      this.showWarningModal(this.translate.instant('youNeedWaitLockupPeriod'));
    }

    this.disclaimerAgreed = this.disclaimerAgreed || (localStorage.getItem("disclaimerAgreed" + this.deal.dealAddress) == this.userInfo.ethAddress);
    if (this.deal.showDisclaimer && !this.disclaimerAgreed){
      const dialogRef = this._dialog.open(DlgDisclaimerComponent, {
        panelClass: ['dlg-light'],
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.disableClose = true;
      dialogRef.componentInstance.disclaimerText = this.deal.disclaimerText;
      dialogRef.componentInstance.disclaimer = this.deal.disclaimer;
      dialogRef.afterClosed().subscribe(result => {
        console.log('DlgDisclaimerComponent result:', result);
        this.disclaimerAgreed = result;
        if (this.disclaimerAgreed) {
          localStorage.setItem("disclaimerAgreed" + this.deal.dealAddress, this.userInfo.ethAddress);
          this.payClickWithWalletAddress();
        }
      });
      return;
    }
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();
    let ticketSizeString = "0x" + new BigNumber(this.usersTicketSize).shiftedBy(this.paymentDecimal).toString(16);
    const contractEventsSource = this.web3Service.dealPay(this.dealAddress, this.account, ticketSizeString,
      this.dealRegistration.signature, this.deal.paymentToken == '0x0000000000000000000000000000000000000000');

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this._alertSrv.show(this.translate.instant('transactionSubmitted'));
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show(this.translate.instant('confirmedTransaction'));
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async payClickWithWalletAddress() {
    if (!this.dealRegistration) {
      this.showWarningModal(this.translate.instant('clickJoinDeal'));
      return;
    }
    if (!this.emissionAddress) {
      this.showWarningModal(this.translate.instant('needToSetEmissionAddress'));
      return;
    }
    if (this.needToWaitTime > 0) {
      this.showWarningModal(this.translate.instant('youNeedWaitLockupPeriod'));
      return;
    }

    // todo check document
    // check if deal requires document (hardcoded in config atm)
    if(documentDeals.includes(this.dealAddress.toLowerCase())){

      if(this.noDocumentsOrAllSignedAndCompleted){
        const addDocument = await this.usersService.setDocumentAmount(this.usersTicketSize).toPromise();
        await this.dealService.addDocument(addDocument.document_hash, this.dealAddress, this.userSessionProvider.linkedWallet, this.usersTicketSize).toPromise();
        this.requiredDocument = true;
        this.noDocumentsOrAllSignedAndCompleted = false;
        this.payInputDisabled = true;
        this.showWarningModal(this.translate.instant('safeAgreementSignatureRequiredJoinDeal'))
        return;
      }
      else{
        const userCanProceed = await this.checkDocumentsAndStatuses();
        if(!userCanProceed){
          return;
        }
      }
    }

    this.disclaimerAgreed = this.disclaimerAgreed || (localStorage.getItem("disclaimerAgreed" + this.deal.dealAddress) == this.userInfo.ethAddress);
    if (this.deal.showDisclaimer && !this.disclaimerAgreed){
      const dialogRef = this._dialog.open(DlgDisclaimerComponent, {
        panelClass: ['dlg-light'],
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.disableClose = true;
      dialogRef.componentInstance.disclaimerText = this.deal.disclaimerText;
      dialogRef.componentInstance.disclaimer = this.deal.disclaimer;
      dialogRef.afterClosed().subscribe(result => {
        console.log('DlgDisclaimerComponent result:', result);
        this.disclaimerAgreed = result;
        if (this.disclaimerAgreed) {
          localStorage.setItem("disclaimerAgreed" + this.deal.dealAddress, this.userInfo.ethAddress);
          this.payClickWithWalletAddress();
        }
      });
      return;
    }

    this.waiting = true;


    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();
    let ticketSizeString = "0x" + new BigNumber(this.usersTicketSize).shiftedBy(this.paymentDecimal).toString(16);
    const contractEventsSource = this.web3Service.dealPayWithEmissionAddress(this.dealAddress, this.account,
      this.emissionAddress, ticketSizeString,
      this.dealRegistration.signature, this.deal.paymentToken == '0x0000000000000000000000000000000000000000');

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this._alertSrv.show(this.translate.instant('transactionSubmitted'));
      });

    try {
      await contractEventsSource.receipt$.toPromise();
      if(this.documentInProgress)
        await this.dealService.completeDocument(this.documentInProgress).toPromise();
      this.requiredDocument = false;
      this.payButtonDisabled = false;
      this.payInputDisabled = false;
      this.documentInProgress = null;
      this.noDocumentsOrAllSignedAndCompleted = true;
      //dialogRef.close();
      this._alertSrv.show(this.translate.instant('confirmedTransaction'));
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }


  async claimClick() {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealClaim(this.dealAddress, this.account);

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this._alertSrv.show(this.translate.instant('transactionSubmitted'));
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show(this.translate.instant('confirmedTransaction'));
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }


  async claimVesingClick() {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.vestingRelease(this.vestingContractAddress, this.account);

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this._alertSrv.show(this.translate.instant('transactionSubmitted'));
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show(this.translate.instant('confirmedTransaction'));
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async updateDealContractData() {
    console.log('Get contract data');

    this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
    if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
      this.web3Service.GetContractSymbol(this.paymentToken).then((resp) => {
        this.paymentTokenSymbol = resp;
      });
      this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
    }
    else {
      //this.paymentTokenSymbol = get from DTO
      this.paymentDecimal = 18;
      this.isApprovedPaymentToken = true;
    }

    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);
    this.rewardToken = await this.web3Service.getDealRewardToken(this.dealAddress).catch(e=>{ console.log('error', e); return null});
    //TODO: if another chain reward token will be empty
    if (this.rewardToken) {
      this.web3Service.GetContractSymbol(this.rewardToken).then((resp) => {
        this.rewardTokenSymbol = resp;
      });
    }

    await this.setRewardDecimals();
    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

    this.dealStartTimestamp = parseInt(await this.web3Service.getDealStartTimestamp(this.dealAddress));
    this.startTimestamp = this.dealStartTimestamp;

    console.log('going to getDealFinishTimestamp');
    this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));
    this.startClaimTimestamp = parseInt(await this.web3Service.getDealStartClaimTimestamp(this.dealAddress));


    this.web3Service.getDealMinimumRaise(this.dealAddress).then((resp) => {
      this.minimumRaise = resp;
    });

    this.web3Service.getDealAllowRefund(this.dealAddress).then((resp) => {
      this.allowRefund = Boolean(resp);
    });


    const tierLength = parseInt(await this.web3Service.getDealTiersLength(this.dealAddress));
    for (let i = 0; i < tierLength; i++) {
      let tier = await this.web3Service.getDealTiers(this.dealAddress, i);

      if (!tier) {
        this.oldDeal = true;
        tier = await this.web3Service.getDealTiersOld(this.dealAddress, i);
      }

      this.allTiers.push(tier);
    }

    this.maxDistributedTokenAmount = this.deal.maxDistributedTokenAmount;

    this.web3Service.getVestingPercent(this.dealAddress).then((resp) => {
      this.vestingPercent = parseInt(resp);
    });

    let vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == "0x0000000000000000000000000000000000000000" ? null : vestAddress;

    if (this.vestingContractAddress) {

      this.web3Service.getVVestingStart(this.vestingContractAddress).then((resp) => {
        this.vestingStart = parseInt(resp);
      });

      this.web3Service.getVVestingInterval(this.vestingContractAddress).then((resp) => {
        this.vestingInterval = parseInt(resp);
      });

      this.web3Service.getVVestingDuration(this.vestingContractAddress).then((resp) => {
        this.vestingDuration = parseInt(resp);
      });
    }
    console.log('check done');
  }


  updateAllowancePaymentToken(ticketSize: number): void {
    if (this.paymentToken != '0x0000000000000000000000000000000000000000') {
      //Проверяем разрешение тратить pool token в размере tokenSupply
      this.web3Service.GetAllowance(this.account, this.paymentToken, this.dealAddress).then((resp) => {
        // console.log(`GetAllowance paymentToken ${resp}`);
        console.log('GetAllowance paymentToken', new BigNumber(resp).shiftedBy(-this.paymentDecimal));
        if (new BigNumber(resp).shiftedBy(-this.paymentDecimal).gte(new BigNumber(ticketSize))) {
          this.isApprovedPaymentToken = true;
        }
        else {
          this.isApprovedPaymentToken = false;
        }
      });
    }
  }


  async approvePaymentClick(): Promise<void> {
    console.log('usersTicketSize', this.usersTicketSize)
    await this.approve(this.usersTicketSize);
  }

  private async approve(amount: number):Promise<boolean>{
    const approveAmount = new BigNumber(amount);
    const amountBN = approveAmount.shiftedBy(this.paymentDecimal);
    this.waiting = true;
    return this.web3Service.approveForDeal(
      this.account,
      this.paymentToken,
      this.dealAddress,
      amountBN.toFixed()
    ).then(() => {
      this._alertSrv.show(this.translate.instant('confirmedTransaction'));
      this.updateAllowancePaymentToken(amount);
      return true;
    }).catch((err) => {
      console.info('catch');
      console.info(err);
      return false;
    }).finally(() => {
      this.waiting = false
    });
  }


  async updateDealDataInterval() {
    this.web3Service.getDealTokensForDistribution(this.dealAddress).then((resp) => {
      this.tokensForDistribution = resp;
    });

    this.web3Service.getDealTotalRaise(this.dealAddress).then((resp) => {
      this.totalRaise = resp;
    });

    this.web3Service.getDealDistributedTokens(this.dealAddress).then((resp) => {
      this.distributedTokens = resp;
    });
  }

  get firstTokenCustomPrice(): string{
    if(this.deal.customPrice){
      return this.deal.customPrice.toString();
    }
    return this.deal.tokenPrice;
  }

  get secondTokenCustomPrice(): string{
    if(this.deal.secondTokenCustomPrice){
      return this.deal.secondTokenCustomPrice.toString();
    }
    return this.deal.tokenPrice;
  }

  get firstTokenDistributionQuantity(){
    let firstTokenPrice = this.deal.customPrice?? this.tokenPrice;
    return (Number(this.totalRaise) * Number(this.deal.investmentPercentage) / Number(100) / Number(firstTokenPrice)).toFixed(2).replace(/[.,]00$/, "");
  }

  get secondTokenDistributionQuantity(){
    if(!this.deal.secondTokenCustomPrice) return '0';
    return (Number(this.totalRaise) * Number(this.deal.secondTokenInvestmentPercentage) / Number(100) / Number(this.deal.secondTokenCustomPrice)).toFixed(2).replace(/[.,]00$/, "");
  }

  get firstTokenMaxDistributedTokenAmount(){
    if(this.deal.customPrice){
      return (Number(this.maxDistributedTokenAmount) * Number(this.deal.investmentPercentage) / Number(100) / Number(this.deal.customPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return this.maxDistributedTokenAmount;
  }

  get secondTokenMaxDistributedTokenAmount(){
    if(this.deal.secondTokenCustomPrice){
      return (Number(this.maxDistributedTokenAmount) * Number(this.deal.secondTokenInvestmentPercentage) / Number(100) / Number(this.deal.secondTokenCustomPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return this.maxDistributedTokenAmount;
  }

  get firstTokenUserTotalDebtAmount(){
    if(this.deal.customPrice){
      return (Number(this.usersDebtAmount) * Number(this.deal.investmentPercentage) / Number(100) / Number(this.deal.customPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");;
    }
    return this.usersDebtAmount;
  }

  get secondTokenUserTotalDebtAmount(){
    if(this.deal.customPrice){
      return (Number(this.usersDebtAmount) * Number(this.deal.secondTokenInvestmentPercentage) / Number(100) / Number(this.deal.secondTokenCustomPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return this.usersDebtAmount;
  }

  getPurchasedFirstTokens(purchaseQuantity: string){
    if(this.deal.customPrice){
      let purchaseQuantityDigit = Number(purchaseQuantity);
      return (purchaseQuantityDigit * Number(this.deal.investmentPercentage) / Number(100) / Number(this.deal.customPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return purchaseQuantity;
  }

  getPurchasedSecondTokens(purchaseQuantity: string){
    if(this.deal.customPrice){
      let purchaseQuantityDigit = Number(purchaseQuantity);
      return (purchaseQuantityDigit * Number(this.deal.secondTokenInvestmentPercentage) / Number(100) / Number(this.deal.secondTokenCustomPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return purchaseQuantity;
  }

  get firstTokenIconUrl(){
    if(this.deal?.tokenIconUrl?.length !=  0){
      return this.deal.tokenIconUrl
    }
    return this.deal?.logoURL ? this.deal.logoURL :'../../assets/images/no-image.svg';
  }

  get secondTokenIconUrl(){
    if(this.deal?.secondTokenIconUrl?.length !=  0){
      return this.deal.secondTokenIconUrl;
    }
    return this.deal?.logoURL ? this.deal.logoURL :'../../assets/images/no-image.svg';
  }

  async setRewardDecimals() {
    //Check if deal without distribution
    console.log('setRewardDecimals')
    let rewardTokenAddress = await this.web3Service.getDealRewardToken(this.dealAddress);
    console.log('rewardTokenAddress', rewardTokenAddress);
    if (!rewardTokenAddress) {
      console.log('setRewardDecimals default 18')
      this.rewardDecimals = 18;
    }
    else {
      console.log('setRewardDecimals response');
      this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
    }
  }

  async updateUserData() {
    console.log('updateUserData');
    await this.setRewardDecimals();
    const allow = await this.web3Service.GetAllowance(this.account, this.paymentToken, this.dealAddress);
    this.allowance = this.toNumberFromWei(allow, 18);
    this.web3Service.getDealUserInfo(this.dealAddress, this.account).then((userInfo) => {
      console.log('userInfo', userInfo);
      this.usersDebtAmount = this.toNumberFromWei(userInfo.debt, this.rewardDecimals);
      this.usersTotalPaymentAmount = this.toNumberFromWei(userInfo.totalPayment, this.paymentDecimal);
      // if(this.deal.customPrice){
      //   this.usersTotalPaymentAmount =  this.usersTotalPaymentAmount * Number(this.deal.customPrice) / Number(this.deal.tokenPrice);
      // }
      this.usersClaimedTokensAmount = this.toNumberFromWei((new BigNumber(userInfo.total)).minus(userInfo.debt).toString(), this.rewardDecimals);
    });

    this.web3Service.getLastParticipations(this.account).then((resp) => {
      this.lastParticipationTime = parseInt(resp);
    });

    let vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == "0x0000000000000000000000000000000000000000" ? null : vestAddress;

    if (this.vestingContractAddress) {
      this.web3Service.getVestingReleasableAmount(this.vestingContractAddress, this.account).then((resp) => {
        this.usersReleasableAmount = this.toNumberFromWei(resp, this.rewardDecimals);
      });

      this.web3Service.getVestingForUser(this.vestingContractAddress, this.account).then((resp) => {
        this.usersVestedAmount = this.toNumberFromWei(resp[0], this.rewardDecimals);
        this.usersVestedReleasedAmount = this.toNumberFromWei(resp[1], this.rewardDecimals);
      });
    }

    var bonusTierInfo = await this.web3Service.getDealUsersBonusTierIndex(this.dealAddress, this.account);
    if (Boolean(bonusTierInfo[0])) {
      this.userBonusTierIndex = parseInt(bonusTierInfo[1]);
    }

    this.web3Service.getDealUsersTierIndex(this.dealAddress, this.account).then(async (resp) => {
      //TODO: check response success
      console.log('getDealUsersTierIndex');
      console.log(resp);
      if (Boolean(resp[0]) || this.userInWhitelist || this.userBonusTierIndex != -1) {
        if (Boolean(resp[0])) {
          this.userTierIndex = parseInt(resp[1]);
        }

        if (this.userBonusTierIndex > this.userTierIndex) {
          this.isBonusActive = true;
          this.userTierIndex = this.userBonusTierIndex;
          this.bonusInterval = parseInt(await this.web3Service.getBonusInterval());
          this.startTimestamp = this.dealStartTimestamp + this.bonusInterval;
        }
        else {
          this.isBonusActive = false;
        }

        this.userTier = await this.web3Service.getDealTiers(this.dealAddress, this.userTierIndex);
        if (!this.userTier) {
          this.userTier = await this.web3Service.getDealTiersOld(this.dealAddress, this.userTierIndex);
        }

        var bonusBalance = Number(await this.web3Service.getBonusAmount(this.web3Service.bonusAddress, this.account));
        const customTier = await this.web3Service.checkCustomTiers(this.dealAddress, this.account);
      
        if (bonusBalance > 0 || customTier > 0) {
          try {
            this.feePercent = this.percentFromInteger(parseInt(await this.web3Service.getDealSpecialTiersFeePercent(this.dealAddress)));
          }
          catch {}
        }
        else {
          this.feePercent = this.percentFromInteger(Number(this.userTier.feePercent));
        }

        console.log('this.userTier', this.userTier, customTier, this.feePercent);

        try {
          this.userCustomRaise = Number(await this.web3Service.getDealUserCustomRaise(this.dealAddress, this.account));
        }
        catch {
          this.userCustomRaise = 0;
        }

        if (this.userCustomRaise > 0) {
          const baseTicketSize = this.allTiers[this.userTierIndex].ticketSize;
          this.userTier.ticketSize = this.increaseTicketSize(baseTicketSize);
          //this.allTiers[this.userTierIndex].ticketSize = this.userTier.ticketSize;
        }
        
        if (this.usersTicketSize == 0) {
          this.usersTicketSize = Number((this.toNumberFromWei(this.userTier.ticketSize, this.paymentDecimal) - this.usersTotalPaymentAmount).toFixed(4));
          this.usersTicketSizeInRewardTokens = Number((this.tokensPerETH * parseFloat((this.toNumberFromWei(this.userTier.ticketSize, this.paymentDecimal) - this.usersTotalPaymentAmount).toFixed(4))).toFixed(4));
          this.usersTicketSizeInRewardTokens = this.decreaseWithFee(this.usersTicketSizeInRewardTokens);
        }
        this.updateAllowancePaymentToken(this.usersTicketSize);

        this.web3Service.getDealLockupsTiers(this.userTierIndex).then(async (resp) => {
          this.lockupDuration = parseInt(resp[1]);
        });
      }
      else {
        console.error(resp);
        console.error(`Tier not found for user: ${this.account}`)
      }
    })
  }


  joinDealClick() {
    console.log('joinDealClick');
    if (this.userSessionProvider.isAuthorized) {
      if (this.kycConfirmed) {
        this.dealService.registrate(this.dealAddress).subscribe(result => {
          this.dealRegistration = result;
          this.showSuccessModal(this.translate.instant('youAreJoined'));
        },
          error => {
            this.processServiceError(error)
          });
      }
      else {
        this.navigateToKYC();
      }
    }
    else {
      this.navigateToLogin();
    }
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else
      this._alertSrv.show(JSON.parse(error.response).message, 'error');
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  navigateToKYC(): void {
    this.router.navigate(["/kyc"]);
  }
  addToGoogleCalendar() {
    const startDate = new Date(this.deal.startTime * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');
    const finishDate = new Date(this.deal.finishTime * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');
    const url = new URL('https://www.google.com/calendar/render');
    const eventInfo = 'Deal details: <a href="https://app.bullperks.com/deal-detail?address=' + this.deal.dealAddress + '">' +
      'https://app.bullperks.com/deal-detail?address=' + this.deal.dealAddress + '</a>';
    url.searchParams.append('action', 'TEMPLATE');
    url.searchParams.append('text', this.deal.name + ' Deal on BullPerks');
    url.searchParams.append('dates', startDate + '/' + finishDate);
    url.searchParams.append('details', eventInfo);
    url.searchParams.append('sf', 'true');
    url.searchParams.append('output', 'xml');

    window.open(url.toString(), '_blank').focus();
  }

  async ngOnDestroy() {
    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
    if (this.updateDealTimerId) {
      clearInterval(this.updateDealTimerId);
    }
    if (this.updateTimeTimerId) {
      clearInterval(this.updateTimeTimerId);
    }
  }

  scrollToDescription() {
    document.getElementById('deal-about').scrollIntoView();
  }

  public openAddAddressDlg() {
    const dialogRef = this._dialog.open(DlgAddressBookComponent, {
      panelClass: ['dlg-light', 'dlg-medium'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.actionAddressMode = 'add';
    let dto = new AddressBookEntryDTO();
    dto.networkId = this.deal.targetNetwork;
    dto.userWallet = this.account;
    dialogRef.componentInstance.dto = dto;
  }

  public get hideClaimButton(): boolean {
    const dealsAddresses = ['0x9713c37c369c7ba510a622ae0f45cbd713e763a9', '0x67473039e98304208174f25935b953c37f34da3b', '0xc96d5d168c6645a1841a7696ad5c01183a37121e', '0x13783198b7da2612afba7624930eb3db44a7a9a1', '0x46e1b24078c7c48dedc41f1bca640624e2651d3a', '0x76ffc28bd8df328aa81a807fcc33096599ce6b6c', '0x62357ddef884b735a72f39311f248c3945a9e907', '0xbd7b229bcf0b9d2d8bc79a5139864a4266b5a484', '0xaa8319638d1981cb19a32ac3a737bb795f140261', '0xcdb4902f7a59f71facc73f17d4fa003b3e536089', '0x34371f1748b209636d4326d78a9a62c8f4bfff50', '0x8290da01a2527a0695fac43fd610b587594b3de5'];
    return dealsAddresses.includes(this.dealAddress);
  }

  getTranslatedTier(tierIndex: number) {
    if (tierIndex == -1)
      return this.translate.instant('none');
    if (tierIndex == 0)
      return this.translate.instant('copperBull');
    if (tierIndex == 1)
      return this.translate.instant('bronzeBull');
    if (tierIndex == 2)
      return this.translate.instant('silverBull');
    if (tierIndex == 3)
      return this.translate.instant('goldenBull');
    if (tierIndex == 4)
      return this.translate.instant('titaniumBull');
    if (tierIndex == 5)
      return this.translate.instant('platinumBull');
    return this.translate.instant('none');
  }

  getTranslatedShowPeriod(value: number): string {
    const timerViewDays = Math.floor(value / (3600 * 24));
    const timerViewHours = Math.floor(value % (3600 * 24) / 3600);
    const timerViewMin = Math.floor(value % 3600 / 60);
    const timerViewSec = Math.floor(value % 60);
    let stringData = "";
    if (timerViewDays)
      stringData += `${timerViewDays} ${this.translate.instant('time.day')} `;
    if (timerViewHours)
      stringData += `${timerViewHours} ${this.translate.instant('time.hours')} `;
    if (timerViewMin)
      stringData += `${timerViewMin} ${this.translate.instant('time.min')} `;
    if (timerViewSec)
      stringData += `${timerViewSec} ${this.translate.instant('time.ss')} `;
    return stringData;
  }

  getTranslatedDealTypeName(value: number): string {
    if (value == 0)
      return this.translate.instant('vc')
    else if (value == 1)
      return this.translate.instant('ido')
    else if (value == 2)
      return this.translate.instant('ino')
    else if (value == 3)
      return this.translate.instant('equity')
    return value.toString();
  }

  hasSocialMedia(): string {
    return (
      this.deal?.twitterURL ||
      this.deal?.mediumURL ||
      this.deal?.youTubeURL ||
      this.deal?.facebookURL ||
      this.deal?.telegramURL ||
      this.deal?.linkedInURL ||
      this.deal?.instagramURL ||
      this.deal?.telegramChannelURL
    );
  }

  public showInfoWhenTierFull(): void {
    swal.fire({
      text: this.translate.instant('tierIsFull'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('goToTelegram'),
    }).then(result => {
      if (result.isConfirmed) {
        window.open('https://t.me/s/BullPerksAnnouncements', '_blank')
      } else if (result.isDenied) {
        return;
      }
    });
  }

  async updateUserTier(): Promise<void> {
    const account = this.userSessionProvider.linkedWallet;
    if (!account) {
      return;
    }

    const tiersLength = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiersLength; i++) {
      const tier = await this.web3Service.getDealLockupsTiers(i);
      this.allLockupsTiers.push(tier);
    }

    this.userLockupsTierIndex = await this.getUserTier();

    console.log(' >>> userLockupsTierIndex', this.userLockupsTierIndex);
  }

  private async getUserTier(): Promise<any> {
    let lockedTotalBalance = await this.web3Service.getLockedTokenAmountTierCalculator(this.web3Service.tierCalculatorAddress, this.userSessionProvider.linkedWallet);
    let lockedBalanceBn = new BigNumber(lockedTotalBalance);
    let tierIndex = 0;
    let success = false;
    for (let i = 0; i < this.allLockupsTiers.length; i++) {
      if (this.allLockupsTiers[i] && lockedBalanceBn.isGreaterThanOrEqualTo(new BigNumber(this.allLockupsTiers[i].blpAmount))
        //Check that next level is higher than previous
        && new BigNumber(this.allLockupsTiers[i].blpAmount).isGreaterThanOrEqualTo(new BigNumber(this.allLockupsTiers[tierIndex].blpAmount))) {
        tierIndex = i;
        success = true;
      }
    }
    return success ? tierIndex : -1;
  }

  public increaseTicketSize(ticketSize: string): string {
    const tiketSizeBn = new BigNumber(ticketSize);
    const multiplier = new BigNumber(this.userCustomRaise + 100);


    return tiketSizeBn.multipliedBy(multiplier).dividedBy(100).toFixed();
  }

  public percentFromInteger(number: number): number {
    return isNaN(number) ? 0 : parseFloat((number / 100.0).toFixed(2));
  }

  public percentToInteger(number: number): number {
    return isNaN(number) ? 0 : Math.round(number * 100);
  }
}
